import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { SpeedInsights } from "@vercel/speed-insights/react";
import { Analytics } from "@vercel/analytics/react";
import NavBar from './components/NavBar';

const Home = lazy(() => import('./pages/Home'));
const About = lazy(() => import('./pages/About'));
const Contact = lazy(() => import('./pages/Contact'));
const Terms = lazy(() => import('./pages/Terms'));
const Privacy = lazy(() => import('./pages/Privacy'));
const ScalpersAndScams = lazy(() => import('./pages/ScalpersAndScams'));
const BookingFees = lazy(() => import('./pages/BookingFees'));
const OpenBanking = lazy(() => import('./pages/OpenBanking'));
const FAQ = lazy(() => import('./pages/FAQ'));
const Event = lazy(() => import('./pages/Event'));
const Footer = lazy(() => import('./components/Footer'));

function App() {
  return (
    <Router>
      <div>
        <NavBar />
        <Suspense fallback={<div></div>}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/scalpers-and-scams" element={<ScalpersAndScams />} />
            <Route path="/booking-fees" element={<BookingFees />} />
            <Route path="/open-banking" element={<OpenBanking />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/event" element={<Event />} />
          </Routes>
        <Footer />
        </Suspense>
        <SpeedInsights />
        <Analytics />
      </div>
    </Router>
  );
}

export default App;
